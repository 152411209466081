import {AvatarInitializer} from '../interfaces/avatar-initializer';
import {AvatarFsm} from './avatar-fsm';
import {CharacterControllerProxy} from './avatar-proxy.controller';
import {FBXLoader} from 'three/examples/jsm/loaders/FBXLoader';
import {AnimationMixer, Group, LoadingManager} from 'three';
import {IdleState} from '../states/idle.state';

export class AvatarInizializerController {
    private _params!: AvatarInitializer;
    public _animations: any = [];
    // private _input: CharacterControllerInput = new CharacterControllerInput();
    private _stateMachine!: AvatarFsm;
    private _target!: Group;
    private _mixer!: AnimationMixer;
    private _manager: LoadingManager = new LoadingManager();

    constructor(params: AvatarInitializer) {
        this._Init(params);
    }

    _Init(params: AvatarInitializer) {
        this._params = params;
        this._LoadModels();
    }

    _LoadModels() {
        const loader = new FBXLoader();
        // loader.setPath('assets/animations/');
        const path = require('../../assets/animations/idle.fbx');
        loader.load(`${path}`, (fbx) => {
            fbx.scale.setScalar(0.1);
            fbx.traverse(c => {
                c.castShadow = true;
            });

            this._target = fbx;
            this._params.scene.add(this._target);

            // https://threejs.org/docs/#api/en/animation/AnimationAction
            this._mixer = new AnimationMixer(this._target);
            /*this._mixer.addEventListener( 'finished', (e) => {
             e.action.stop();
             this._stateMachine.SetState('idle');
             });*/
            // la faccio loopare per non avere limiti e poi la stoppo a mano
            this._stateMachine = new AvatarFsm(new CharacterControllerProxy(this._animations));
            this._mixer.addEventListener('loop', (e) => {
                // console.log(e);
                // @ts-ignore
                if (!(this._stateMachine._currentState._parent._currentState instanceof IdleState)) {
                    console.log('stopped', this._stateMachine._currentState?.Name);
                    e.action.stop();
                    this._stateMachine.SetState('idle');
                }
            });

            this._manager.onLoad = () => {
                if (!this?._stateMachine?._currentState) {
                    console.log('first loading, setting idle statement');
                    this._stateMachine.SetState('idle');
                }
            };

            this._LoadState('idle');
            this._LoadState('Anim_01');
            this._LoadState('Anim_02');
            this._LoadState('Anim_03');
            this._LoadState('Anim_04');
            this._LoadState('Anim_05');
            this._LoadState('Anim_06');
            this._LoadState('Anim_07');
            this._LoadState('Anim_08');
            this._LoadState('Anim_09');
            this._LoadState('Anim_10');
        });
    }

    _LoadState(state: string) {
        const loader = new FBXLoader(this._manager);
        loader.load(require(`../../assets/animations/${state}.fbx`), (a) => this._OnLoad(state, a));
    }

    _OnLoad = (animName: string, anim: Group) => {
        if (!(animName in this._animations)) {
            const clip = anim.animations[0];
            const action = this._mixer.clipAction(clip);

            console.log('loading', animName);
            this._animations[animName] = {
                clip: clip,
                action: action,
            };
        } else {
            console.log('already exists', animName);
        }
    };

    _UpdateState(state: string) {
        this._stateMachine.SetState(state);
    }

    Update(timeInSeconds: number) {
        if (!this._target) {
            return;
        }

        if (this._mixer) {
            this._mixer.update(timeInSeconds);
        }
    }
}
