import React, {CSSProperties, useEffect} from 'react';
import {IonContent, IonIcon, IonLabel, IonPage, isPlatform} from '@ionic/react';
import './Tab1.css';
import {useSwipeable} from 'react-swipeable';
import {chevronBack, chevronForward} from 'ionicons/icons';
import {AvatarController} from '../avatar/controller/avatar.controller';

function badgeStyle(index: number): CSSProperties {
    return {
        left: `calc(${index}*(80vw + 20px))`,
        cursor: 'pointer',
    };
}

let avatar: AvatarController;

function createAvatar(): void {
    avatar = new AvatarController(window.innerWidth, window.innerHeight);
}

const Tab1: React.FC = () => {
    useEffect(() => {
        createAvatar();
    }, []);

    const frasi = {
        Anim_01: 'Frase 01',
        Anim_02: 'Frase 02',
        Anim_03: 'Frase 03',
        Anim_04: 'Frase 04',
        Anim_05: 'Frase 05',
        Anim_06: 'Frase 06',
        Anim_07: 'Frase 07',
        Anim_08: 'Frase 08',
        Anim_09: 'Frase 09',
        Anim_10: 'Frase 10',
    }
    const frasiArrayValues = Object.values(frasi)

    const [fraseAttiva, setFraseAttiva] = React.useState(0);

    const containerStyle: CSSProperties = {
        width: `calc(${Object.keys(frasi).length}*(80vw + 20px))`,
        left: `calc(-${fraseAttiva}*(80vw + 20px) + 10vw - 10px)`,
    };

    const swipeSentence = useSwipeable({
        onSwipedLeft: () => {
            if (fraseAttiva < Object.keys(frasi).length - 1) {
                setFraseAttiva(fraseAttiva + 1);
                // avatar.loadState(`Anim_0${fraseAttiva + 2}`) // qui fraseAttiva si è ancora incrementata di +1, quindi dovendo aggiungere +1 di default (perché fraseAttiva va da 0 a 9 ma i file da 1 a 10), faccio +2
            }
        },
        onSwipedRight: () => {
            if (fraseAttiva > 0) {
                setFraseAttiva(fraseAttiva - 1);
                // avatar.loadState(`Anim_0${fraseAttiva}`) // qui fraseAttiva si è ancora decrementata di -1, quindi dovendo aggiungere +1 di default (perché fraseAttiva va da 0 a 9 ma i file da 1 a 10), faccio +1 -1 => 0, quindi fraseAttiva
            }
        },
        trackMouse: true,
    });

    return (
        <IonPage className={'content-style'}>
            <IonContent>
                <div className="uk-card uk-card-default uk-card-body webcamContainer"
                     style={{height: '100%', width: '100%', backgroundColor: 'var(--ion-color-primary)'}}>
                    <div id="avatarScene"/>
                </div>
                <div style={containerStyle} className={'container-style'} {...swipeSentence}>
                    {
                        Object.keys(frasi).map((key: string, index: number) =>
                            <div style={badgeStyle(index)} className={'badge-style'}
                                 onClick={
                                     event => {
                                         console.log(key, index);
                                         avatar.setState(key, event);

                                     }
                                 }>
                                <IonLabel style={{fontSize: '18pt', fontWeight: 'bold'}}>
                                    {frasiArrayValues[index]}
                                </IonLabel>
                            </div>,
                        )
                    }
                </div>
                <div className={'left-button'}
                     onClick={event => {
                         setFraseAttiva(fraseAttiva > 0 ? fraseAttiva - 1 : fraseAttiva);
                         // avatar.loadState(`Anim_0${(fraseAttiva > 0 ? fraseAttiva - 1 : fraseAttiva) + 1}`, event)
                     }}>
                    <IonIcon icon={chevronBack}
                             style={{
                                 color: (isPlatform('desktop') || isPlatform('tablet')) ? 'var(--ion-color-primary)' : 'transparent',
                                 fontSize: '100px',
                             }}/>
                </div>
                <div className={'right-button'}
                     onClick={event => {
                         setFraseAttiva(fraseAttiva < Object.keys(frasi).length - 1 ? fraseAttiva + 1 : fraseAttiva);
                         // avatar.loadState(`Anim_0${(fraseAttiva < frasi.length - 1 ? fraseAttiva + 1 : fraseAttiva) + 1}`, event)
                     }}>
                    <IonIcon icon={chevronForward}
                             style={{
                                 color: (isPlatform('desktop') || isPlatform('tablet')) ? 'var(--ion-color-primary)' : 'transparent',
                                 fontSize: '100px',
                             }}/>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Tab1;
